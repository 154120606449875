/* eslint-disable @typescript-eslint/no-redeclare */
// tslint:disable
/**
 * api-spec-boilerplate
 * Build API server fast and get API spec and documentation for free - Node.JS boilerplate
 *
 * The version of the OpenAPI document: 1.0.16
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import { fetchMiddleware } from "../../infrastructure/fetch.middleware";
const portableFetch = fetchMiddleware;

const BASE_PATH = process.env.REACT_APP_API_SERVER_URL || "http://localhost".replace(/\/+$/, "");


/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/** The request options */
export interface RequestOptions {
    /** A callback to be called on each job update come from server */
    progressCallback?: (jobProgress: JobProgress) => void;
}

export interface JobFunction {
    method: (xJobFlag: "OFF", jobId: string) => Promise<JobState>;
    theThis: any;
    requestOptions?: RequestOptions;
}

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, jobFunction: JobFunction, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name!: "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * The job flag, mark whenever the operation should be executed as a job or not
 * @export
 * @enum {string}
 */

export const JobFlag = {
    On: 'ON',
    Off: 'OFF'
} as const;

export type JobFlag = typeof JobFlag[keyof typeof JobFlag];


/**
 * 
 * @export
 * @enum {string}
 */

export const JobFlagOFF = {
    Off: 'OFF'
} as const;

export type JobFlagOFF = typeof JobFlagOFF[keyof typeof JobFlagOFF];


/**
 * The job progress status
 * @export
 * @interface JobProgress
 */
export interface JobProgress {
    /**
     * The job progress in %
     * @type {number}
     * @memberof JobProgress
     */
    'percentage': number;
    /**
     * 
     * @type {string}
     * @memberof JobProgress
     */
    'message'?: string;
}
/**
 * The job state
 * @export
 * @interface JobState
 */
export interface JobState {
    /**
     * 
     * @type {string}
     * @memberof JobState
     */
    'jobId': string;
    /**
     * Job starting timestamp in EPOCH
     * @type {number}
     * @memberof JobState
     */
    'startedOn': number;
    /**
     * Job finished timestamp in EPOCH, undefined if not finished yet
     * @type {number}
     * @memberof JobState
     */
    'finishedOn'?: number;
    /**
     * 
     * @type {JobStatus}
     * @memberof JobState
     */
    'status': JobStatus;
    /**
     * The job results payload, undefined if not yet finished
     * @type {any}
     * @memberof JobState
     */
    'results'?: any;
    /**
     * 
     * @type {JobProgress}
     * @memberof JobState
     */
    'progress': JobProgress;
}
/**
 * Job status
 * @export
 * @enum {string}
 */

export const JobStatus = {
    Done: 'DONE',
    Started: 'STARTED',
    InProgress: 'IN_PROGRESS',
    Failed: 'FAILED'
} as const;

export type JobStatus = typeof JobStatus[keyof typeof JobStatus];


/**
 * Long pong payload
 * @export
 * @interface LongPong
 */
export interface LongPong {
    /**
     * The time the action took overall
     * @type {number}
     * @memberof LongPong
     */
    'timeTook': number;
}
/**
 * 
 * @export
 * @interface Ping
 */
export interface Ping {
    /**
     * Who is the request caller (free text)
     * @type {string}
     * @memberof Ping
     */
    'whois': string;
}
/**
 * 
 * @export
 * @interface Pong
 */
export interface Pong {
    /**
     * The greeting message arrived from the API caller
     * @type {string}
     * @memberof Pong
     */
    'greeting': string;
    /**
     * The tome when the ping request arrived
     * @type {number}
     * @memberof Pong
     */
    'time': number;
}

/**
 * JobsApi - fetch parameter creator
 * @export
 */
export const JobsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the status of a given job
         * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
         * @param {string} jobId The job id to get status of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJob(xJobFlag: JobFlagOFF, jobId: string, options: any = {}): FetchArgs {
            // verify required parameter 'xJobFlag' is not null or undefined
            if (xJobFlag === null || xJobFlag === undefined) {
                throw new RequiredError('xJobFlag','Required parameter xJobFlag was null or undefined when calling getJob.');
            }
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling getJob.');
            }
            const localVarPath = `/jobs/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xJobFlag !== undefined && xJobFlag !== null) {
                localVarHeaderParameter['x-job-flag'] = String(xJobFlag);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

            const anonimusObj = localVarUrlObj as any;
            delete anonimusObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove job from the jobs collection
         * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
         * @param {string} jobId The job id to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeJob(xJobFlag: JobFlagOFF, jobId: string, options: any = {}): FetchArgs {
            // verify required parameter 'xJobFlag' is not null or undefined
            if (xJobFlag === null || xJobFlag === undefined) {
                throw new RequiredError('xJobFlag','Required parameter xJobFlag was null or undefined when calling removeJob.');
            }
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling removeJob.');
            }
            const localVarPath = `/jobs/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xJobFlag !== undefined && xJobFlag !== null) {
                localVarHeaderParameter['x-job-flag'] = String(xJobFlag);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

            const anonimusObj = localVarUrlObj as any;
            delete anonimusObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get the status of a given job
         * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
         * @param {string} jobId The job id to get status of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */  
        getJob(xJobFlag: JobFlagOFF, jobId: string, options?: any, requestOptions?: RequestOptions): (fetch?: FetchAPI, basePath?: string) => Promise<JobState> {
            const localVarFetchArgs = JobsApiFetchParamCreator(configuration).getJob(xJobFlag, jobId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, { method: ApiFacade.JobsApi.getJob, theThis: this, requestOptions }, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Remove job from the jobs collection
         * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
         * @param {string} jobId The job id to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */  
        removeJob(xJobFlag: JobFlagOFF, jobId: string, options?: any, requestOptions?: RequestOptions): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = JobsApiFetchParamCreator(configuration).removeJob(xJobFlag, jobId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, { method: ApiFacade.JobsApi.getJob, theThis: this, requestOptions }, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get the status of a given job
         * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
         * @param {string} jobId The job id to get status of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJob(xJobFlag: JobFlagOFF, jobId: string, options?: any) {
            return JobsApiFp(configuration).getJob(xJobFlag, jobId, options)(fetch, basePath);
        },
        /**
         * Remove job from the jobs collection
         * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
         * @param {string} jobId The job id to remove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeJob(xJobFlag: JobFlagOFF, jobId: string, options?: any) {
            return JobsApiFp(configuration).removeJob(xJobFlag, jobId, options)(fetch, basePath);
        },
    };
};

/**
 * JobsApi - interface
 * @export
 * @interface JobsApi
 */
export interface JobsApiInterface {
    /**
     * Get the status of a given job
     * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
     * @param {string} jobId The job id to get status of
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApiInterface
     */
    getJob(xJobFlag: JobFlagOFF, jobId: string, options?: any): Promise<JobState>;

    /**
     * Remove job from the jobs collection
     * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
     * @param {string} jobId The job id to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApiInterface
     */
    removeJob(xJobFlag: JobFlagOFF, jobId: string, options?: any): Promise<{}>;

}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI implements JobsApiInterface {
    /**
     * Get the status of a given job
     * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
     * @param {string} jobId The job id to get status of
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public getJob(xJobFlag: JobFlagOFF, jobId: string,  requestOptions?: RequestOptions) {
        return JobsApiFp(this.configuration).getJob(xJobFlag, jobId,  undefined, requestOptions)(this.fetch, this.basePath);
    }

    /**
     * Remove job from the jobs collection
     * @param {JobFlagOFF} xJobFlag Force operation to NOT be run as job
     * @param {string} jobId The job id to remove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public removeJob(xJobFlag: JobFlagOFF, jobId: string,  requestOptions?: RequestOptions) {
        return JobsApiFp(this.configuration).removeJob(xJobFlag, jobId,  undefined, requestOptions)(this.fetch, this.basePath);
    }

}

/**
 * StatusApi - fetch parameter creator
 * @export
 */
export const StatusApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send long ping that can take some time to finished, this operation can be invoked as a job.
         * @param {JobFlag} xJobFlag The flag whenever run this operation as a job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longPing(xJobFlag: JobFlag, options: any = {}): FetchArgs {
            // verify required parameter 'xJobFlag' is not null or undefined
            if (xJobFlag === null || xJobFlag === undefined) {
                throw new RequiredError('xJobFlag','Required parameter xJobFlag was null or undefined when calling longPing.');
            }
            const localVarPath = `/status/long`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xJobFlag !== undefined && xJobFlag !== null) {
                localVarHeaderParameter['x-job-flag'] = String(xJobFlag);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

            const anonimusObj = localVarUrlObj as any;
            delete anonimusObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send Ping request to API server.
         * @param {string} greeting The greeting to send :)
         * @param {Ping} [ping] The ping payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(greeting: string, ping?: Ping, options: any = {}): FetchArgs {
            // verify required parameter 'greeting' is not null or undefined
            if (greeting === null || greeting === undefined) {
                throw new RequiredError('greeting','Required parameter greeting was null or undefined when calling ping.');
            }
            const localVarPath = `/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (greeting !== undefined) {
                localVarQueryParameter['greeting'] = greeting;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943

            const anonimusObj = localVarUrlObj as any;
            delete anonimusObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            // eslint-disable-next-line
            const needsSerialization = (<any>"Ping" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ping || {}) : (ping || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Send long ping that can take some time to finished, this operation can be invoked as a job.
         * @param {JobFlag} xJobFlag The flag whenever run this operation as a job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */  
        longPing(xJobFlag: JobFlag, options?: any, requestOptions?: RequestOptions): (fetch?: FetchAPI, basePath?: string) => Promise<LongPong> {
            const localVarFetchArgs = StatusApiFetchParamCreator(configuration).longPing(xJobFlag, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, { method: ApiFacade.JobsApi.getJob, theThis: this, requestOptions }, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Send Ping request to API server.
         * @param {string} greeting The greeting to send :)
         * @param {Ping} [ping] The ping payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */  
        ping(greeting: string, ping?: Ping, options?: any, requestOptions?: RequestOptions): (fetch?: FetchAPI, basePath?: string) => Promise<Pong> {
            const localVarFetchArgs = StatusApiFetchParamCreator(configuration).ping(greeting, ping, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, { method: ApiFacade.JobsApi.getJob, theThis: this, requestOptions }, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Send long ping that can take some time to finished, this operation can be invoked as a job.
         * @param {JobFlag} xJobFlag The flag whenever run this operation as a job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longPing(xJobFlag: JobFlag, options?: any) {
            return StatusApiFp(configuration).longPing(xJobFlag, options)(fetch, basePath);
        },
        /**
         * Send Ping request to API server.
         * @param {string} greeting The greeting to send :)
         * @param {Ping} [ping] The ping payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(greeting: string, ping?: Ping, options?: any) {
            return StatusApiFp(configuration).ping(greeting, ping, options)(fetch, basePath);
        },
    };
};

/**
 * StatusApi - interface
 * @export
 * @interface StatusApi
 */
export interface StatusApiInterface {
    /**
     * Send long ping that can take some time to finished, this operation can be invoked as a job.
     * @param {JobFlag} xJobFlag The flag whenever run this operation as a job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApiInterface
     */
    longPing(xJobFlag: JobFlag, options?: any): Promise<LongPong>;

    /**
     * Send Ping request to API server.
     * @param {string} greeting The greeting to send :)
     * @param {Ping} [ping] The ping payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApiInterface
     */
    ping(greeting: string, ping?: Ping, options?: any): Promise<Pong>;

}

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI implements StatusApiInterface {
    /**
     * Send long ping that can take some time to finished, this operation can be invoked as a job.
     * @param {JobFlag} xJobFlag The flag whenever run this operation as a job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public longPing(xJobFlag: JobFlag,  requestOptions?: RequestOptions) {
        return StatusApiFp(this.configuration).longPing(xJobFlag,  undefined, requestOptions)(this.fetch, this.basePath);
    }

    /**
     * Send Ping request to API server.
     * @param {string} greeting The greeting to send :)
     * @param {Ping} [ping] The ping payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public ping(greeting: string, ping?: Ping,  requestOptions?: RequestOptions) {
        return StatusApiFp(this.configuration).ping(greeting, ping,  undefined, requestOptions)(this.fetch, this.basePath);
    }

}


export class ApiFacade {
    public static JobsApi = new JobsApi();
    public static StatusApi = new StatusApi();
}
